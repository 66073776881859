import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Axios from "./common/Baseurl";
import swal from "sweetalert";
import img from "../assets/images/1 (2).png";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

function Signin() {
  let history = useHistory();
  // console.log(history.action)
  // useEffect(() => {
  //   if (history.action === "REPLACE") return swal("Please login first!");
  // }, [history.action]);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);
  const firebaseConfig = {
  // apiKey: "AIzaSyAe7mPT15KD7uBQb6AMAXMZmZ2QbREzJGU",
  // authDomain: "globechain-333208.firebaseapp.com",
  // projectId: "globechain-333208",
  // storageBucket: "globechain-333208.appspot.com",
  // messagingSenderId: "63414059633",
  // appId: "1:63414059633:web:09680c31115240607291d0",
  // measurementId: "G-LCVR28CB16"

    apiKey: "AIzaSyD3zwlBnsks9lEb0zwMre-hczdeR15p-VE",
    authDomain: "accounting-automation-336110.firebaseapp.com",
    projectId: "accounting-automation-336110",
    storageBucket: "accounting-automation-336110.appspot.com",
    messagingSenderId: "367664052390",
    appId: "1:367664052390:web:2ab22724e1727ce5599c67",
    measurementId: "G-L16NQE9HBP"

};

// Initialize Firebase
const App = initializeApp(firebaseConfig);

// const analytics = getAnalytics(App);
const auth = getAuth();
auth.languageCode = 'en';


  const code = '';
  // confirmationResult.confirm(code).then((result) => {
  //   // User signed in successfully.
  //   const user = result.user;
  //   // ...
  // }).catch((error) => {
  //   // User couldn't sign in (bad verification code?)
  //   // ...
  // });



  const login = (e) => {
    e.preventDefault();
    setloading(true);
    Axios.post("/login", {
      email: email,
      password: password,
    })
      .then(async (response) => {
        if (!response.data.auth) {
          setloading(false);
          await swal(response.data.message, "", "info");
          // setemail("");
          // setPassword("");
        } else {

           const phoneNumber = response.data.user.phone;
           window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
           const appVerifier = window.recaptchaVerifier;
           signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              swal("Enter 6 digit code here here:", {
                content: {
                  element: "input",
                  attributes: {
                    type: 'text',
                    required: 'required'
                  },
                },
                buttons: {
                  confirm: {
                    text: 'Verify',
                    closeModal: false,
                  },
                },
                closeOnClickOutside: false,
                allowEscapeKey: false,
                closeOnConfirm: false
              })
                .then((value) => {
                 confirmationResult.confirm(value).then((result) => {
                  //  User signed in successfully.
                    const user = result.user;
                    setloading(false);
                   localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("userInfo", JSON.stringify(response.data.user));
                    history.push("/profile");
                    //console.log(result.user)
                    swal.close()
                  }).catch((erro) => {
                    swal('Failed','Invalid code','warning')
                    window.location.reload(false)
                    localStorage.removeItem('userInfo')
                    localStorage.removeItem('token')
                    swal.close()
                    history.push("/signin");
                  });
               });
              //console.log(confirmationResult)
            }).catch((error) => {
              swal('SMS not sent')
            //  console.log(error)
            });

        }
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          setloading(false);
          if (err.response.status >= 400 && err.response.status <= 499)
            return swal(
              "Uh-oh.",
              "something was not right on your end, please try again",
              "info"
            );
          else if (err.response.status >= 500 && err.response.status <= 599)
            return swal(
              "Uh-oh..Error 500",
              "Something went wrong at our end. Sorry about that",
              "info"
            );
        } else if (err.request) {
          // client never received a response, or request never left
          setloading(false);
          return swal(
            "Network error",
            "The network connection is lost,please try after some time",
            "info"
          );
        } else {
          // anything else
          setloading(false);
          return swal("something went wrong", "", "info");
        }
      });
  };

  return (
    <>
      <Navbar />

      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="d-lg-flex justify-content-lg-end d-none w-100 position-relative img-cont">
          <h2 className="floating">Log in to your account</h2>
          <img src={img} alt="login-img" />
        </div>
        <form
          onSubmit={login}
          className="container w-100 d-flex flex-column align-items-center p-sm-3 p-5"
        >
          <h2>User Login</h2>
          <div className="mt-sm-2 mt-3 mb-3 width-custom">
            <label style={{ color: "#363636" }} className="form-label">
              Userid
            </label>
            <input
              style={{ backgroundColor: "#DCE9FA", outline: "none" }}
              className="form-control border-0 rounded-pill py-2 px-4"
              type="text"
              id="email"
              name="email"
              autoComplete="off"
              placeholder="Userid"
              value={email}
              required
              onChange={(e) => setemail(e.target.value)}
            ></input>
          </div>
          <div className="mb-sm-2 mb-3 width-custom">
          <label style={{ color: "#363636" }} className="form-label">
              Password
            </label>
            <input
              style={{ backgroundColor: "#DCE9FA", outline: "none" }}
              className="form-control border-0 rounded-pill py-2 px-4"
              type="password"
              name="password"
              id="password"
              autoComplete="off"
              placeholder="Password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          <button
            className="my-sm-2 my-3 btn btn-primary rounded-pill px-4 py-1"
            type="submit" id=""
          >
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Sign In"
            )}
          </button>
          <div className="text-center" style={{ color: "#363636" }}>
            Don't have an account? {""}{" "}
            <Link style={{ textDecoration: "none", color: "#0564DC" }} to="/">
              Sign up for free!
            </Link>
          </div>
          <div id="recaptcha-container"></div>
        </form>
      </div>
    </>
  );
}

export default Signin;

// import React, { useEffect, useState } from "react";
// import Axios from "axios";
// import "../App.css";

// export default function Login() {
//   const [email, setemail] = useState("");
//   const [password, setPassword] = useState("");

//   const login = () => {
//     Axios.post("http://localhost:7000/login", {
//       email: email,
//       password: password,
//     }).then((response) => {
//       if (response.data.length > 0) {
//         window.alert('login successfully')
//       } else {
//         window.alert('error');
//       }
//     });
//   };

//   return (
//     <div className="App">
//       <div className="login">
//         <h1>Login</h1>
//         <input
//           type="text"
//           placeholder="Username..."
//           onChange={(e) => {
//             setemail(e.target.value);
//           }}
//         />
//         <input
//           type="password"
//           placeholder="Password..."
//           onChange={(e) => {
//             setPassword(e.target.value);
//           }}
//         />
//         <button onClick={login}> Login </button>
//       </div>
//     </div>
//   );
// }
