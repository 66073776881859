import React from "react";
import { NavLink, withRouter } from "react-router-dom";
// import { useDispatch } from "react-redux";
import logo from "../../assets/images/imgpsh_fullsize_anim.png";
// import { signout } from "../../actions/userActions";
function Navbar(props) {
  return (
    <nav style={{ backgroundColor: "white" }} className="navbar navbar-light">
      <div className="container pt-3 pb-3">
        <img
          className="img-fluid"
          style={{ height: "80px" }}
          src="/LOGO.png"
          alt=""
        />
        <div className="d-flex">
          <NavLink
            style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.9)" }}
            activeStyle={{ borderBottom: "1px solid rgba(0,0,0,0.7)" }}
            exact
            to="/"
            className="me-5 font-weight-bold text-decoration-none"
          >
            Register
          </NavLink>
          <NavLink
            style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.9)" }}
            activeStyle={{ borderBottom: "1px solid rgba(0,0,0,0.7)" }}
            to="/signin"
            className="font-weight-bold text-decoration-none"
          >
            Login
          </NavLink>
        </div>
      </div>
    </nav>
  );
}

export default withRouter(Navbar);
