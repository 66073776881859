import React, { useState, useEffect } from 'react'
import instance from '../../common/Baseurl'
import { useParams } from 'react-router-dom'
import Filesinsidesubcategory from '../Folders/Filesinsidesubcategory'

function Details(props) {
    const [loading, setLoading] = useState(false)
    const { id, category } = useParams()
    const [state, setstate] = useState({
        key: '',
        active: false
    })
    const [sub_category, setsub_category] = useState([])
    useEffect(() => {
        instance.get('/subCategoryInsideCategory/' + id + '/' + category)
            .then(res => {
                setsub_category(res.data)
            })
            .catch(err => console.log(err))
            setLoading(true)
    }, [id,category])
    if(loading && sub_category.length>0){
        setstate({
            ...state,
            key: sub_category[0].sub_category,
            active: true
        })
        setLoading(false)
    }
    const handleClick = (key) => {
        setstate({
            ...state,
            key: key,
            active: true
        })
        // props.history.push(`/profile/${id}/${category}/${key}`)
    }
    if (sub_category.length===0) {
        <div className='container mt-5'>
            <div className='card'>
                <div className='card-body'>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    }
    
    return (
        <div className='container mt-3'>
            <div className="row">
                {sub_category && sub_category.map((data,key) => {
                    return (
                        <div key={key} className="col-sm-12 col-md-2 mb-3">
                            <div onClick={() => handleClick(data.sub_category)}
                                className={`card shadow text-center tab-card ${state.key === data.sub_category && state.active ? 'active' : ''}`}>
                                <div className='card-body'>
                                    <h5 className='card-title'>{data.sub_category}</h5>
                                    <h2><i style={{ width: '3rem', height: '3rem' }} className="ri-pages-line"></i></h2>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            

                <div className='mt-2'>
                    <Filesinsidesubcategory id={id} category={category} sub_category={state.key}/>
                </div>

        </div>
    )
}
export default Details
