export const PRODUCT_LOADING_REQUEST = 'PRODUCT_LOADING_REQUEST';
export const PRODUCT_LOADING_SUCCESS = 'PRODUCT_LOADING_SUCCESS';
export const PRODUCT_LOADING_FAIL = 'PRODUCT_LOADING_FAIL';

export const READ_FOLDERS = 'READ_FOLDERS';
export const REMOVE_FOLDERS = 'REMOVE_FOLDERS';

export const READ_FILES = 'READ_FILES';
export const REMOVE_FILES = 'REMOVE_FILES';
export const READ_USER_FOLDER = 'READ_USER_FOLDER';
export const REMOVE_USER_FOLDER = 'REMOVE_USER_FOLDER'