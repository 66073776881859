import React, { useEffect, useState} from "react";
// import { connect } from "react-redux";
import "../Dashboard.css";
import { Link,withRouter } from "react-router-dom";
import instance from '../../common/Baseurl'
import swal from "sweetalert";
import { connect } from "react-redux";
import { fetchFolders,fetchUserFolder } from '../../../actions/productActions'
// import img from "../../../assets/images/free-sample-pdf-to-download.jpg";

function Myfiles(props) {
  const [id, setid] = useState(null);
  const [user_name, setusername]=useState(null);
  const [createfile, setcreatefile] = useState(false);
  const [loading, setloading] = useState(false);
  const [foldername, setfoldername] = useState('')
  const user = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
  
    const { id , name} = JSON.parse(localStorage.getItem("userInfo"));
    setid(id);
    setusername(name)
    return ()=>{
      setid(null);
      setfoldername('');
    }
  }, []);
  
  const onSubmitHandler = async(e)=>{
    e.preventDefault();
    if(foldername.trim() === '') {
      await swal({
        text:'Enter a folder name first'
      })
      setfoldername('')
      return 
    }
    let f_name= foldername.toLowerCase();
    let result = f_name.search("/");
    if (result != -1) {
       await swal({
        text:'Folder name must not contain "/" and "\"'
      })
      setfoldername('')
      return
    }
    if(props.folders[0]){
      let result = props.folders[0].find( e =>e.name.trim() === f_name.trim() )
      if(result){
        if( f_name.trim()===result.name.trim() && props.folders[0].length>0){
        swal(
          `Folder ${foldername} already exists`,
          '',
          "warning",
        );
        setloading(false);
        setfoldername('');
        return;
        }
      }
      }
    setloading(true);
    instance.post( `/createFolder`,{
      name : foldername,
      id : props.userFolder.id
    }).then(async res=>{
      setloading(false);
      await swal("Successfully created!","", "success");
      setcreatefile(false)
      await props.fetchFolders(props.userFolder.id);
      props.history.push(`/profile/folders/files/${res.data.id}/${foldername}/${user.user_id}`)
    })
    .catch(err =>{
      if (err.response) {
        // client received an error response (5xx, 4xx)
        setloading(false);
        if (err.response.status >= 400 && err.response.status <= 499)
          {
            if(err.response.status !== 406) return swal(
              "Uh-oh.",
              "something was not right on your end, please try again",
              "info"
            );
            return swal("File already exists")
          }
        else if (
          err.response.status >= 500 &&
          err.response.status <= 599
        )
          return swal(
            "Uh-oh..Error 500",
            "Something went wrong at our end. Sorry about that",
            "info"
          );
      } else if (err.request) {
        // client never received a response, or request never left
        setloading(false);
        return swal(
          "Network error",
          "The network connection is lost,please try after some time",
          "info"
        );
      } else {
        // anything else
        console.log(err);
        setloading(false);
        return swal("something went wrong", "", "info");
      }
     });
  }
  return (
    <div className="w-100 h-100 p-5">
      {/* <div className="text-center w-100 alert alert-primary p-4">
        <Link style={{ textDecoration: "none" }} to="/profile/upload">
          Upload a file
        </Link>
      </div> */}
      <div
        className={
          createfile
            ? "d-flex justify-content-center text-center w-100 p-4 mb-3"
            : "text-center w-100 p-4 alert alert-primary mb-3"
        }
      >
        <Link
          style={{ textDecoration: "none", display: createfile ? "none" : "" }}
          onClick={(e) => {
            e.preventDefault();
            setcreatefile(!createfile);
          }}
          to=""
        >
          Create a Folder
        </Link>
        <form
          style={{
            display: createfile ? "" : "none"
          }}
          className="width-custom"
          onSubmit={onSubmitHandler}
        >
          <label style={{ color: "#363636" }} className="form-label">
            Enter folder name
          </label>
          <input
            type="text"
            placeholder="folder name"
            style={{ backgroundColor: "#DCE9FA", outline: "none" }}
            id="company"
            autoComplete="off"
            value={foldername}
            name='foldername'
            onChange={(e) => setfoldername(e.target.value)}
            className="form-control border-0 rounded-pill py-2 px-4"
          />
          <button
            className="mt-3 btn btn-sm btn-primary rounded-pill px-4 py-2"
            type="submit"
          >
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Create"
            )}
          </button>
          <button
            className="mt-3 btn btn-sm btn-secondary rounded-pill px-4 py-2"
            onClick={(e)=>{
              e.preventDefault();
              setfoldername('')
              setloading(false)
              setcreatefile(false)
            }}
          >
            Cancel
          </button>
        </form>
      </div>
      {/* <div className="w-100">
        <Singlefile />
      </div> */}
    </div>
  );
}
const mapDispatchToProps = ({
  fetchFolders,
  fetchUserFolder
})
const mapStateToProps = (state) => {
  return {
    folders: state.getFolders.folders,
    userFolder : state.getUserFolder.userfolder
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Myfiles));
