import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import instance from "../../common/Baseurl";
function Singlefile(props) {
  const [state, setstate] = useState(null);
  const [filteredstate, setfilteredstate] = useState(null);
  const { folders } = props;
  const [clients, setClients] = useState([]);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    if (state && state.length > 0) {
      result = state.filter((d) => {
        return d.name.toLowerCase().includes(value)
      });
    }
    setfilteredstate(result);
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (folders && folders.length !== 0) {
      const data = [];
      const filtereddata = [];
      folders[0].map((f) => {
        data.push(f);
        filtereddata.push(f);
        setstate(data);
        setfilteredstate(filtereddata);
      });
      if (user.user_id != 0 && user.user_type == 2){
        instance.get(`/clients/${user.user_id}`).then(res =>{
          // setClients(res.data)
          var da=[]
          if(res.data.length>0){
              filtereddata.map((f) => {
                res.data.map((d) => {
                  if (d.user_id != f.name) {
                    da.push({ "id": f.id, "name": f.name });
                    // console.log(f.name)
                  }

                })
              })
              setfilteredstate('')
              setfilteredstate(da)
          }
        }
          ).catch(err => console.log(err))
      }
    }
    // else{
    //   props.history.push('/500')
    // }
    // return () => {
    //   setstate(null);
    // };
  }, [folders]);
  let product = "";
  if (filteredstate && filteredstate.length > 0) {
    const unique = Array.from(filteredstate.reduce((a, o) => a.set(o.id, o), new Map()).values());
    unique.sort((a,b)=>a.name.localeCompare(b.name, undefined, { caseFirst: "upper" }))
    product = unique.map((f, i) => {
      return (
        <NavLink
          style={{ textDecoration: "none" }}
          to={`/profile/folders/files/${f.id}/${f.name}/${user.user_id}`}
          key={f.id}
          className="hover-link"
          activeStyle={{ backgroundColor: "#222222" }}
        >
          <li className="w-100 list-group-item list-group-item-light bg-transparent text-light text-capitalize">
            {f.name}
          </li>
          {/* <i className="ri-delete-bin-fill"></i> */}
        </NavLink>
      );
    });
  }
  return (
    <>
      {props.folders.length > 0 ? (
        <>
          <div className="m-3">
          <label className="form-label text-light">Search folder:</label>
            <input onChange={(event) => handleSearch(event)}  type="text" className="form-control form-control-sm"/>
          </div>
          <ul className="w-100 list-group list-group-flush">{product}</ul>
        </>
      ) : ( 
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    folders: state.getFolders.folders,
  };
};

export default withRouter(connect(mapStateToProps)(Singlefile));
