import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, NavLink, useHistory } from "react-router-dom";
import { fetchFiles, removeuserFiles } from ".././../../actions/productActions";
import { BsFillGridFill } from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import { filter } from "async";
import instance from "../../common/Baseurl";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { MDBDataTableV5 } from 'mdbreact';
function Admins() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const [datatable, setDatatable]=useState(false)
    const [loading, setloading] = useState(true);
    let history = useHistory();
    let { userid, usertype } = useParams(0)
    const columns = [
        {
            label: "Name",
            field: "name",
            sort:"asc"
        },
        {
            label: "Contact",
            field: "contact",
            sort: "asc"
        },
        {
            label: "Email",
            field: "email",
            sort: "asc"
        },
        {
            label: "Company",
            field: "company",
            sort: "asc"
        },
        {
            label: "Loign id",
            field: "loginID",
            sort: "asc"
        },
        {
            field:"files"
        },
        {
            field:"clients"
        }
    ]
    useEffect(()=>{
        if (user.user_id != 0) {

            instance.get(`/admins/${userid ? userid : user.user_id}/${usertype ? usertype : user.user_type}/${user.access?user.access:0}`).then(res =>{
                var row=[];
                res.data.map((data)=>{
                    let str = data.company.split(' ');
                    row.push({
                        name:data.name,
                        contact:data.phone,
                        email:data.email,
                        company:data.company,
                        loginID: str ? str[0] + '-' + data.user_id : data.company + '-' + data.user_id,
                        files: <NavLink style={{ textAlign: "center", float: "right" }} exact to = {`/profile/admins/folders/${data.user_id}/${data.name}`} className = "d-sm-flex d-none align-items-center text-decoration-none" > <button className="Downbutton">    <span style={{ marginLeft: ".5rem" }}>Manage files</span> </button> </NavLink >,
                        clients: <NavLink style={{ textAlign: "center", float: "right" }} exact to = {`/profile/clients/${data.user_id}/${data.user_type}`} className = "d-sm-flex d-none align-items-center text-decoration-none" > <button className="Downbutton">   <span style={{ marginLeft: ".5rem" }}>Manage direct clients</span>   </button> </NavLink >
                    })
                })
                setDatatable({
                    columns: columns,
                    rows: row
                })
                setloading(false)})
                .catch(err => console.log(err))


        }
    },[userid,usertype])



    return (
        <div className="position-relative w-100 h-100 p-5">
            <div className="row">
                <div className="col-sm-6">
                    <h3><i className="ri-arrow-left-circle-line"
                           onClick={(e) => {
                               e.preventDefault();
                               history.goBack();
                           }}
                           style={{ color: '#084caa', float: 'left' }}>
                    </i> Professional Intermediary</h3>
                </div>
                <div className="col-sm-6">
                    <NavLink
                        style={{ textAlign: "center", float: "right" }}
                        exact
                        to={`/profile/register/admins/${userid ? userid : user.user_id}/${usertype ? usertype : user.user_type}`}
                        className="d-sm-flex d-none align-items-center text-decoration-none"
                    ><button className="Downbutton">
                            <i className="ri-file-add-fill"></i>{" "}
                            <span style={{ marginLeft: ".5rem" }}>Create new</span>
                        </button>
                    </NavLink>
                </div>
            </div>
            <hr/>
            {
                loading ? (
                    <div
                        className={
                            loading
                                ? "w-100 h-100 d-flex justify-content-center align-items-center"
                                : ""
                        }
                    >
                        <div className={"spinner-border text-primary"} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ):
                (
                        <MDBDataTableV5
                        hover
                        entriesOptions={[25, 50, 100]}
                        entries={25} pagesAmount={4}
                        data={datatable}
                        pagingTop
                        searchTop
                        searchBottom={false}
                        barReverse />
                )
            }
        </div>
    );
}
export default Admins;
