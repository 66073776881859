import PropTypes from 'prop-types'
import { getCountries } from 'react-phone-number-input'

const CountrySelect = ({ value, onChange, labels, ...rest }) => {
    return(
  <select
    {...rest}
    value={value}
    onChange={event => {
      onChange(event.target.value || undefined)
      }}>
    <option value="">
      Choose a Country
    </option>
    {getCountries().map((country) => (
      <option key={country} value={country}>
        {labels[country]}
      </option>
    ))}
  </select>
)
    }
CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired
}
export default CountrySelect 