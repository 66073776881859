import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, NavLink, useHistory } from "react-router-dom";
import { fetchFiles, removeuserFiles } from ".././../../actions/productActions";
import { BsFillGridFill } from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import { filter } from "async";
import instance from "../../common/Baseurl";
function ClientFolders() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    let {id,name}=useParams();
    const[folders,setFolders] = useState([]);
    const [loading, setloading] = useState(true);
    let history = useHistory();
    useEffect(() => {
        const fetch = () => {

            instance
                .post("/getUserFolder", { user_id: id, user_type: 3, created_by: user.user_id })
                .then((res) => {
                    instance
                        .get("/getFileFolder/" + res.data.id)
                        .then((resu) => {
                            setloading(false)
                            setFolders(resu.data.files)
                            if(resu.data.files.length>0){
                                let obj = resu.data.files.find(o => o.name === 'Asset');
                                if (obj === undefined) {
                                    instance.post(`/createFolder`, {
                                        name: 'Asset',
                                        id: res.data.id
                                    }).then(rest => fetch()).catch(errr => console.log(errr))
                                }
                            }
                            else {
                                instance.post(`/createFolder`, {
                                    name: 'Asset',
                                    id: res.data.id
                                }).then(rest => fetch()).catch(errr => console.log(errr))
                            }
                        });
                });

        }
        fetch()
    }, [])
    
    return (
        <div className="position-relative w-100 h-100 p-5 overflow-auto">
            <div className="row">
                <div className="col-sm-6">
                    <h3><i className="ri-arrow-left-circle-line"
                        onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        style={{ color: '#084caa', float: 'left' }}>
                    </i> {name}</h3>                    
                </div>
                {/* <div className="col-sm-6">
                    <NavLink
                        style={{ textAlign: "center", float: "right" }}
                        exact
                        to={`/profile/register/clients`}
                        className="d-sm-flex d-none align-items-center text-decoration-none"
                    ><button className="Downbutton">
                            <i className="ri-file-add-fill"></i>{" "}
                            <span style={{ marginLeft: ".5rem" }}>Create new client</span>
                        </button>
                    </NavLink>
                </div> */}
            </div>
            <hr/>
            {
                loading ? (
                    <div
                        className={
                            loading
                                ? "w-100 h-100 d-flex justify-content-center align-items-center"
                                : ""
                        }
                    >
                        <div className={"spinner-border text-primary"} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ):
                folders.length===0
                ?
                (
                    <h4 className="text-center w-100 p-4 alert alert-primary mb-3">No data</h4>
                )
                :
                (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                folders && folders.map(data=>{
                                    return(
                                    <tr>
                                        <td>{data.name}</td>
                                        <td><NavLink
                                            style={{ textAlign: "center", float: "right" }}
                                            exact
                                            to={`/profile/folders/files/${data.id}/${data.name}/${id}`}
                                            className="d-sm-flex d-none align-items-center text-decoration-none"
                                        ><button className="Downbutton">
                                                <span style={{ marginLeft: ".5rem" }}>View files</span>
                                            </button>
                                        </NavLink></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    );
}
export default ClientFolders;
 