import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, NavLink, useHistory } from "react-router-dom";
import { fetchFiles, removeuserFiles } from ".././../../actions/productActions";
import Singlefileinfolders from "./Singlefileinfolders";
import { BsFillGridFill} from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import { filter } from "async";

function Filesinfolders(props) {
  let { id,name,user_id } = useParams();
  const [loading, setloading] = useState(false);
  const [state, setstate] = useState(null);
  const [url, seturl] = useState("");
  const [type, settype] = useState("");
  const [thumbnail, setthumbnail] = useState("");
  const [sortbyName, setsortbyName] = useState(false);
  const [sortbyDate, setsortbyDate] = useState(false);
  const [sortbyExtn, setsortbyExtn] = useState(false);
  const [viewbyList, setviewbyList] = useState(true);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  let history = useHistory();
  const getUrl = (url) => seturl(url); //get url of the file
  const getfileType = (type) => settype(type); //get extension of file
  const getThumbnail = (tn) => setthumbnail(tn); //get thumbnail of file

  useEffect(() => {
    var elem;
    async function loadingData() {
      setloading(true);
      if (props.folders[0] !== undefined) {
        elem = props.folders[0].find((folder) => folder.id === id);
        await setstate(elem);
      }
      await props.fetchFiles(id);
      setloading(false);
    }
    loadingData();
    return () => {
      setstate(null);
      seturl(null);
      settype(null);
    };
  }, [props.folders, id]);
  return (
    <div className="position-relative w-100 h-100 p-5 d-sm-flex justify-content-sm-center align-items-sm-start">
      {loading ? (
        <div
          className={
            loading
              ? "w-100 h-100 d-flex justify-content-center align-items-center"
              : ""
          }
        >
          <div className={"spinner-border text-primary"} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="w-100">
            <button
              style={{ backgroundColor: "transparent" }}
              className="mb-4 border-0 border-bottom text-primary border-primary"
              onClick={(e) => {
                e.preventDefault();
                props.removeuserFiles();
                history.push("/profile");
              }}
            >
              Go to home
            </button>

            {user.user_type===4?'':<NavLink
                  style={{textAlign:"center", float:"right"}}
                  exact
                  to={`/profile/folders/upload/${id}/${state?state.name:name?name:''}/${user_id?user_id:''}`}
                  className={"d-sm-flex d-none align-items-center text-decoration-none "}
                ><button className="Downbutton">
                  <i className="ri-upload-2-line"></i>{" "}
                  <span style={{ marginLeft: ".5rem" }}>Upload a file</span>
                  </button>
                </NavLink>}
            <div className="w-100 d-flex justify-content-between align-items-center">
                <h2 className="text-capitalize"><i className="ri-arrow-left-circle-line"
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                  style={{ color: '#084caa', float: 'left' }}>
                </i>{state ? state.name : name ? name: ""}</h2>
              <div className="d-flex justify-content-between align-items-center">
                <div className={props.files[0] && props.files[0].length===0?"d-none":"dropdown d-sm-block"}>

                 { viewbyList?
                  <BsFillGridFill onClick={()=> setviewbyList(false)}/> : <FaThList onClick={()=> setviewbyList(true)}/>
                 }
                  <button
                    className="btn btn-sm bg-transparent dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="ri-filter-line"></i>
                    View by
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        setsortbyName(true);
                        setsortbyDate(false);
                        setsortbyExtn(false);
                      }}
                    >
                      <a className="dropdown-item" href="!#">
                        Name
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        setsortbyDate(true);
                        setsortbyName(false);
                        setsortbyExtn(false);
                      }}
                    >
                      <a className="dropdown-item" href="!#">
                        Recently uploaded
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        setsortbyExtn(true);
                        setsortbyDate(false);
                        setsortbyName(false);
                      }}
                    >
                      <a className="dropdown-item" href="!#">
                        Extensions
                      </a>
                    </li>
                  </ul>
                </div>

                <NavLink
                  style={{ color: "#363636", marginLeft: "1rem" }}
                  exact
                  to={`/profile/folders/upload/${id}`}
                  className="d-sm-none d-flex align-items-center text-decoration-none"
                >
                  <i className="ri-upload-2-line"></i>{" "}
                </NavLink>
                <div className={props.files[0] && props.files[0].length===0?"d-none":"dropdown d-sm-none"}>
                  <button
                    className="btn btn-sm bg-transparent dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="ri-filter-line"></i>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        setsortbyName(true);
                        setsortbyDate(false);
                        setsortbyExtn(false);
                      }}
                    >
                      <a className="dropdown-item" href="!#">
                        Name
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        setsortbyDate(true);
                        setsortbyName(false);
                        setsortbyExtn(false);
                      }}
                    >
                      <a className="dropdown-item" href="!#">
                        Recently uploaded
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        setsortbyExtn(true);
                        setsortbyDate(false);
                        setsortbyName(false);
                      }}
                    >
                      <a className="dropdown-item" href="!#">
                        Extensions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <hr />
            <div className="w-100">
              <Singlefileinfolders
                filethumbnail={getThumbnail}
                fileurl={getUrl}
                filetype={getfileType}
                sortbyName={sortbyName}
                sortbyDate={sortbyDate}
                sortbyExtn={sortbyExtn}
                viewbyList={viewbyList}
              />
            </div>
          </div>
          {/* <div className={props.files[0] && props.files[0].length===0?"d-none":"h-100 hr-width d-flex justify-content-center"}>
            <Filepreview url={url} thumbnail={thumbnail} />
          </div> */}
        </>
      )}
    </div>
  );
}

const mapDispatchToProps = {
  fetchFiles,
  removeuserFiles,
};
const mapStateToProps = (state) => {
  return {
    folders: state.getFolders.folders,
    files: state.getFiles.files,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filesinfolders);
