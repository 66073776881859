import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, NavLink, useHistory } from "react-router-dom";
import { getFilesInsideSubCategory, removeuserFiles } from ".././../../actions/productActions";
import instance from "../../common/Baseurl";
import swal from "sweetalert";

function Filesinfolders(props) {
  let id = props.id;
  let category = props.category
  let sub_category = props.sub_category;
  const [loading, setloading] = useState(false);
  const [state, setstate] = useState(null);
  const [url, seturl] = useState("");
  const [type, settype] = useState("");
  const [thumbnail, setthumbnail] = useState("");
  const user = JSON.parse(localStorage.getItem("userInfo"));

  let history = useHistory();
  const getUrl = (url) => seturl(url); //get url of the file
  const getfileType = (type) => settype(type); //get extension of file
  const getThumbnail = (tn) => setthumbnail(tn); //get thumbnail of file
  useEffect(() => {
    var elem;
    async function loadingData() {
      setloading(true);
      if (props !== undefined) {
        // elem = props.match.find((folder) => {
        id = id
        category = category
        sub_category = sub_category
        // });
        await setstate(elem);
      }
      await props.getFilesInsideSubCategory(id, category, sub_category);
      setloading(false);
    }
    loadingData();
    return () => {
      setstate(null);
      seturl(null);
      settype(null);
    };
  }, [id, category, sub_category]);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    var baseURL = 'https://demo.accounting.globe-chain.com:7000'
    // var baseURL = 'http://localhost:7000/'
  }
  else {
    var baseURL = 'https://accounting.globe-chain.com:7000'

  }
  const download = (event, id, name, user_id, user_name) => {
    event.preventDefault();
    event.stopPropagation()
    // swal('Downloading file', { buttons: false })
    // instance.get(`downloadFiles/${id}/${name}/${user_id}/${user_name}`)
    //   .then(res => {
    //     // console.log(res.data)
    //     if (res.data.response == "success") {
    //       setTimeout(function () {
    //         swal.close()
    //         // let url = window.URL.createObjectURL(blob);
    //         let a = document.createElement('a');
    //         a.href = `/uploads/${user_name}-${user_id}/${name}`;
    //         a.download = `${name}`;
    //         a.click();
    //       }, 2000)

    //     }
    //   })
    //   .catch(err => console.log(err))
    window.open(`${baseURL}/download/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}/${encodeURIComponent(baseURL)}`, '_blank')
    // window.open(`http://localhost:7000/download/${user_name}-${user_id}/${id}/${name}`,'_blank')
  }

  const report = (event, id, name, user_id, user_name) => {
    event.preventDefault()
    event.stopPropagation()
    // swal('Generating report from file', { buttons: false })
    // instance.get(`downloadFiles/${id}/${name}/${user_id}/${user_name}`)
    //   .then(res => {
    //     // console.log(res.data)
    //     if (res.data.response == "success") {
    //       setTimeout(function () {
    //         instance.get(`report/${user_name}-${user_id}/${id}/${name}`)
    //           .then(result => {
    //             // console.log(result.data)
    //             if (result.data.response == "success") {
    //               setTimeout(function(){
    //                 let a = document.createElement('a');
    //                 a.href = `/uploads/${user_name}-${user_id}/${name}.xls`;
    //                 a.download = `${name}.xls`;
    //                 a.click();
    //                 swal.close()
    //               },2000)
    //             }
    //           })
    //           .catch(error => console.log(error))
    //       }, 2000)

    //     }
    //   })
    //   .catch(err => console.log(err))
    window.open(`${baseURL}/report/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}/${encodeURIComponent(baseURL)}`, '_blank')
    // window.open(`http://localhost:7000/report/${user_name}-${user_id}/${id}/${name}`, '_blank')
  }
  const preview = (event, id, name, user_id, user_name) => {
    event.preventDefault()
    event.stopPropagation()
    // swal('Processing file',{buttons:false})
    // instance.get(`downloadFiles/${id}/${name}/${user_id}/${user_name}`)
    //   .then((res) => {
    //     if(res.data.response=="success")
    //     {
    //       setTimeout(function(){
    //         window.open(`/uploads/${user_name}-${user_id}/${name}`,'_blank')
    //         swal.close()
    //       },1000)
    //     }
    // })
    // .catch(err => console.log(err))
    window.open(`${baseURL}/preview/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}/${encodeURIComponent(baseURL)}`, '_blank')
    // window.open(`http://localhost:7000/preview/${user_name}-${user_id}/${id}/${name}`, '_blank')
  }


  const deleteFile = (event, name, id,userid) => {
    event.preventDefault()
    event.stopPropagation()
    swal('Are you sure', 'You want to delete this file?', 'info', { buttons: { cancel: 'No', view: 'Yes' } }).then((view) => {
      if (view == 'view') {
        // setloading(true)
        swal('Deleting file', { buttons: false })
        instance.delete(`deleteFile/${encodeURIComponent(name)}/${id}/${userid}`).then(res => {
          // setloading(false) 
          // console.log(res.data)
          if (res.data.status == "success") {
            swal.close()
            window.location.reload(false)
          }
        }).catch(err => console.log(err))
      }
    })
  }

  return (
    <div className="position-relative w-100 h-100 overflow-auto d-sm-flex justify-content-sm-center align-items-sm-start">
      {loading ? (
        <div
          className={
            loading
              ? "w-100 h-100 d-flex justify-content-center align-items-center"
              : ""
          }
        >
          <div className={"spinner-border text-primary"} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <hr />
            <div className="w-100 table-responsive">
                <table className="table">
                {props.files[0] && props.files[0].length === 0 ? <div className="text-center mt-5 container alert alert-primary p-2">Nothing uploaded</div> :
                  <>
                    <thead>
                      <tr className="table-primary table-striped">
                        <th scope="col">File Name</th>
                        <th scope="col">Folder Name</th>
                        <th scope="col">Calender</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>


                    <tbody>
                      {props.files.length > 0 && props.files[0].map(data => {
                        return (
                          <tr key={data.id} className="table-primary"
                          onClick={(e)=> preview(e,data.id, data.name, user.id, user.name)}>
                            <td>{data.name}</td>
                            <td>{data.folder_name ? data.folder_name : ''}</td>
                            <td>{new Date(data.createdTime).toDateString()}</td>
                            <td className="text-nowrap">
                              <>
                                <button
                                  data-toggle="tooltip" data-placement="left" title="Download"
                                  style={{ color: '#0564DC', backgroundColor: "transparent", justifyContent: 'center', border: 'none' }} onClick={(e) => { download(e, data.id, data.name, user.id, user.name) }} type="button"><i className="ri-xl mx-2 ri-download-2-line"></i></button>

                                <button
                                  data-toggle="tooltip" data-placement="left" title="Delete"
                                  style={{ color: '#0564DC', backgroundColor: "transparent", justifyContent: 'center', border: 'none' }} onClick={(e) => { deleteFile(e, data.name, data.id, user.user_id) }} type="button">{loading ? (
                                    <div className="spinner-border text-light border-primary" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                    </div>
                                  ) : <i className="ri-xl mx-2 ri-delete-bin-7-line"></i>}</button>

                                <button
                                  data-toggle="tooltip" data-placement="left" title="Preview"
                                  style={{ color: '#0564DC', backgroundColor: "transparent", justifyContent: 'center', border: 'none' }} onClick={(e) => { preview(e, data.id, data.name, user.id, user.name) }} type="button"><i className="ri-xl mx-2 ri-eye-fill"></i></button>

                              </>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </>}
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapDispatchToProps = {
  getFilesInsideSubCategory,
  removeuserFiles,
};
const mapStateToProps = (state) => {
  return {
    folders: state.getFolders.folders,
    files: state.getFiles.files,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filesinfolders);
