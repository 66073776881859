import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import instance from "../../common/Baseurl";

function Userprofilepic() {
  const [loading, setloading] = useState(false);
  const [id, setid] = useState("");
  const [name, setName] = useState("");
  const ref = useRef();
  const [file, setFile] = useState("");
  const [notloaded, setnotloaded] = useState(true);
  const [uploadedFile, setUploadedFile] = useState({});
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    setid(user.id);
    setName(user.name)
  }, []);
  const onChange = (e) => {
    setFile(e.target.files[0]);
    setnotloaded(false);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (ref.current.value === "") return swal("Please select a file first!");
    if (
      ref.current.value.split(".").pop() !== "jpg" &&
      ref.current.value.split(".").pop() !== "jpeg" &&
      ref.current.value.split(".").pop() !== "png"
    ) {
      swal("Invalid file formats.", "Only .jpg, .jpeg and .png are valid");
      setnotloaded(true);
      ref.current.value = "";
      setFile("");
      return;
    }
    setloading(true);
    const formData = new FormData();
    formData.append("content", file);
    formData.append("id", id);
    formData.append("name", name);
    instance
      .post("/uploadProfilePic", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "x-access-token": localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        if (res.status === 200) {
          setloading(false);
          const user = JSON.parse(localStorage.getItem("userInfo"));
          user['profile_pic'] = res.data.user[0].profile_pic;
          localStorage.setItem(
            "userInfo",
            JSON.stringify(user)
          );
          await swal("File uploaded successfully!", "", "success");
          setnotloaded(true);
          ref.current.value = "";
          setFile("");
          history.push("/profile/folders");
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setloading(false);
          swal(
            "There was a problem with the server",
            "Please try again after sometime",
            "info"
          );
        } else if (err.response.status === 406) {
          setloading(false);
          swal("File already exists");
          ref.current.value = "";
          setFile("");
        } else {
          setloading(false);
          swal(
            "Uh-oh, something was not right on your end",
            "please try again",
            "info"
          );
          ref.current.value = "";
          setFile("");
        }
      });
  };
  return (
    <div className="position-relative w-100 h-100 p-5 overflow-auto">
      <form
        onSubmit={onSubmit}
        className="dimensions-upload container d-flex flex-column justify-content-evenly align-items-center rounded-3 overflow-auto"
      >
        <div className="custom-file">
          <h5 style={{textAlign:"center"}}>Upload your picture</h5>
          
          <input
            type="file"
            accept="image/*"
            className="form-control custom-file-input mt-3"
            id="customFile"
            onChange={onChange}
            ref={ref}
          />
        </div>
        <button
          // style={{display: notloaded ? 'none' : 'block'}}
          className="btn btn-primary rounded-pill px-3 py-1 mt-3"
          type="submit"
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Upload file/image"
          )}
        </button>
        <p className="text-center">
          Supported file extensions: .jpg,.jpeg,.pdf,.png
        </p>
        <button
          className="btn btn-outline-primary"
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          Go back
        </button>
      </form>
    </div>
  );
}

export default Userprofilepic;
