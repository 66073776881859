import axios from 'axios'

var isProd = process.env.live;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
{
    var baseURL = 'https://demo.accounting.globe-chain.com:7000/'
}
else{
    var baseURL = 'https://accounting.globe-chain.com:7000/'
    // var baseURL = 'http://localhost:7000/'
}
const instance = axios.create({
    
    // baseURL: 'http://13.235.217.159:7000/'
    //  baseURL: 'http://localhost:7000/'
    //  baseURL: 'http://13.244.139.126:7000/'
    //baseURL:'http://ec2-13-235-217-159.ap-south-1.compute.amazonaws.com/'
    baseURL: baseURL 
})

export default instance   