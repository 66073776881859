// import Axios from "axios";
import {
  READ_FOLDERS,
  REMOVE_USER_FOLDER,
  REMOVE_FOLDERS,
  READ_FILES,
  REMOVE_FILES,
  READ_USER_FOLDER
} from "../constants/productConstants";
import axios from "../components/common/Baseurl";

// export const getProducts = () => (dispatch) => {
//   dispatch({ type: PRODUCT_LOADING_REQUEST });
//   Axios.get("/api/products/zoho")
//     .then((response) => {
//       return dispatch({
//         type: PRODUCT_LOADING_SUCCESS,
//         payload: response.data.createdProducts,
//       });
//     })
//     .catch((error) => {
//       return dispatch({
//         type: PRODUCT_LOADING_FAIL,
//         payload:
//           error.response && error.response.data.message
//             ? error.response.data.message
//             : error.message,
//       });
//     });
// };

//to get all the folders created by a user
export const fetchFolders = (id) => async (dispatch) => {

  await axios
    .get("/getFileFolder/" + id, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    
    .then((res) => {
      
      const data = [];
      const val = res.data.files.map((folder) => {
        var id = folder.id;
        var name = folder.name;
        return { id, name };
      });
      data.push(val);
      return dispatch({
        type: READ_FOLDERS,
        payload: data,
      });
    })
    .catch((err) => console.log(err.request));
};

//to get all the files uploaded in a particular folder
export const fetchFiles = (id) => async (dispatch) => {
  await axios
    .get("/getFileFolder/" + id, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((res) => {
      const data = [];
      const val = res.data.files.map((file) => {
        const id = file.id;
        const name = file.name;
        // const folder_id = file.attributes.parent_id;
        let res = file.name.split(".");
        const extension = res[1];
        const createdTime = file.createdTime;
        // const link = file.attributes.permalink;
        // const thumbnail = file.attributes.thumbnail_url;
        // const rawtime = new Date(file.attributes.created_time_in_millisecond);
        return {
          id,
          name,
          // folder_id,
          extension,
          createdTime,
          // link,
          // thumbnail,
          // rawtime,
        };
      });
      data.push(val);
      return dispatch({
        type: READ_FILES,
        payload: data,
      });
    })
    .catch((err) => console.log(err.request));
};

//to get all the files uploaded in a sub category
export const getFilesInsideSubCategory = (id,category,sub_category) => async (dispatch) => {
  await axios
    .get("/getFilesInsideSubCategory/" + id+"/"+category+"/"+sub_category, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((res) => {
      const data = [];
      const val = res.data.files.map((file) => {
        const id = file.id;
        const name = file.name;
        // const folder_id = file.attributes.parent_id;
        let res = file.name.split(".");
        const extension = res[1];
        const createdTime = file.createdTime;
        const folder_id = file.folder_id
        const folder_name = file.folder_name
        // const link = file.attributes.permalink;
        // const thumbnail = file.attributes.thumbnail_url;
        // const rawtime = new Date(file.attributes.created_time_in_millisecond);
        return {
          id,
          name,
          folder_id,
          extension,
          createdTime,
          folder_name,
          // link,
          // thumbnail,
          // rawtime,
        };
      });
      data.push(val);
      return dispatch({
        type: READ_FILES,
        payload: data,
      });
    })
    .catch((err) => console.log(err.request));
};
// export const createUserFolder = (id,name)=> async (dispatch) => {
//   axios
//           .post("/createUserFolder", {
//             user_name: String(name),
//             user_id: id,
//           })
//           .then((res) => {
//             console.log('Createuserfolder',res);
//           });
// }
//to get the root folder of the user which contains all the user created folders
export const fetchUserFolder = (id, name, created) => async (dispatch) => {
  await axios
    .post("/getUserFolder", { user_type: name, user_id: id, created_by: created })
    .then((res) => {
      const { id, name } = res.data;
      return dispatch({
        type: READ_USER_FOLDER,
        payload: {id,name},
      });
    });
};
export const removeuserrootFolders = () => {
  return {
    type: REMOVE_USER_FOLDER,
  };
};
export const removeuserFolders = () => {
  return {
    type: REMOVE_FOLDERS,
  };
};
export const removeuserFiles = () => {
  return {
    type: REMOVE_FILES,
  };
};

// export const storeFile = (file) => {
//   return {
//     type: FILE_UPLOAD,
//     payload: file,
//   };
// };
