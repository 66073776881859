import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNOUT,
  USER_SIGNIN_SUCCESS
} from "../constants/userConstants";

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_SIGNOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// export const fileuploadReducer = (state = {}, action) => {
//   switch (action.type) {
//     case FILE_UPLOAD_REQUEST:
//         return { loading: true };
//     case FILE_UPLOAD_SUCCESS:
//         return {loading : false, file: action.payload};
//     case FILE_UPLOAD_FAIL:
//         return {loading : false, error: action.payload};
//     default:
//         return state
//   }
// };

export default userSigninReducer;
