import React, { useEffect, useState } from 'react'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryPie, VictoryBar } from 'victory';
import { connect } from "react-redux";
import instance from '../../common/Baseurl';
import {
    removeuserFolders,
    removeuserFiles,
    fetchUserFolder,
    removeuserrootFolders,
    fetchFolders
} from '../../../actions/productActions'

function AccountData(props) {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const [userFolder, setuserFolder] = useState([])
    const { fetchUserFolder, fetchFolders } = props;
    const [loading, setLoading]=useState(false)
    const [data, setData] = useState([])
    const [count, setCount] = useState([])
    useEffect(() => {
        const fetch = () => {

            instance
                .post("/getUserFolder", { user_id: user.user_id, user_type:user.user_type,created_by:user. created_by })
                .then((res) => {
                    instance
                        .get("/getFileFolder/" + res.data.id)
                        .then((resu) => {
                            // console.log(resu.data)
                            const ar = []
                            resu.data.files.map((f) => {
                                instance
                                    .get("/getFileFolder/" + f.id)
                                    .then((resul) => {
                                        setLoading(true);
                                        ar.push({ 'name': f.name, 'count': resul.data.files.length })
                                        // setData(ar)
                                    })
                                    .catch(err=> console.log(err))
                                    .then(()=>{
                                        setData(ar) 
                                        setLoading(false)
                                    })
                            })
                        });
                });

        }
        fetch()
    }, [])
    return (
        <div className='accountdata-main position-relative w-200 h-200 p-1 justify-content-sm-center align-items-sm-start'>
            <div className='accountant-data-body container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className="accountdata shadow card my-2 p-2">
                            <h5 style={{ fontWeight: 600 }} className="card-title">Income Express</h5>
                            <VictoryChart theme={VictoryTheme.material} width={400} height={200}>

                                <VictoryLine
                                    style={{
                                        data: { stroke: "#a500a2" },
                                        parent: { border: "1px solid #ccc" }
                                    }}
                                    data={[
                                        { x: 0, y: 0 },
                                        { x: 10, y: 10 },
                                        { x: 20, y: 40 },
                                        { x: 30, y: 55 },
                                        { x: 40, y: 60 },
                                        { x: 50, y: 75 },
                                        { x: 60, y: 70 },
                                        { x: 70, y: 85 },
                                        { x: 80, y: 65 },
                                        { x: 90, y: 85 },
                                        { x: 200, y: 95 }
                                    ]}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-12 col-sm-12'>
                        <div className="accountdata shadow card my-2 p-2">
                            <h5 style={{ fontWeight: 600 }} className="card-title text-center">Liabilities</h5>
                            <VictoryPie
                                colorScale='cool'
                                width={200} height={200}
                                style={{ labels: { fill: "#000" } }}
                                labels={({ datum }) => `${Math.round(datum.y / 10 * 200)}%`}
                                data={[
                                    { x: 0, y: 2 },
                                    { x: 2, y: 3 },
                                    { x: 3, y: 5 }
                                ]}
                            />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-12 col-sm-12'>
                        <div className="accountdata shadow card my-2 p-2">
                            <h5 style={{ fontWeight: 600 }} className="card-title text-center">Receivable</h5>
                            <VictoryPie
                                width={400} height={400}
                                colorScale="qualitative"
                                style={{ labels: { fill: "#000", fontSize: 20 } }}
                                labels={({ datum }) => `${Math.round(datum.y / 15 * 200)}%`}
                                radius={({ datum }) => 40 + datum.y * 30}
                                data={[
                                    { x: 0, y: 2 },
                                    { x: 1, y: 2 },
                                    { x: 2, y: 3 },
                                    { x: 3, y: 4 },
                                    { x: 4, y: 3 },
                                    { x: 5, y: 1 }
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-6' >
                        <div className="accountdata shadow card my-2 p-4">
                            <h5 style={{ fontWeight: 600 }} className="card-title">File Summary</h5>
                            {loading ? 
                            <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                           : 
                            <>
                            {
                                data && data.map((res, key) => {
                                    return (
                                        <>
                                            <div key={key} className='row mb-2'>
                                                <div className='col-sm-8 text-bold text-primary text-capitalize'>{res.name}</div>
                                                <div className='col-sm-4 float-right text-bold'>{res.count}</div>
                                            </div>
                                        </>
                                    )
                                })
                            }</>}
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6 ' >
                        <div className="accountdata shadow card my-2">
                            <h5 style={{ fontWeight: 600 }} className="card-title">Overview</h5>

                            <VictoryBar
                                width={400} height={150}
                                style={{
                                    data: { fill: "#ccc" },
                                }}
                                barRatio={1.5}
                                data={[
                                    { x: 1, y: 2 },
                                    { x: 2, y: 3 },
                                    { x: 3, y: 4 },
                                    { x: 4, y: 5 },
                                    { x: 5, y: 6 }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    removeuserFolders,
    removeuserFiles,
    fetchUserFolder,
    removeuserrootFolders,
    fetchFolders
};
const mapStateToProps = (state) => {
    return {
        userFolder: state.getUserFolder.userfolder
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountData);
