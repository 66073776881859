import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import userSigninReducer, {
  userRegisterReducer,
} from "./reducers/userReducers";
import { getFoldersReducer,getFilesReducer,getUserFolderReducer } from "./reducers/productReducers";

const initialState = {};
const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  getFolders: getFoldersReducer,
  getFiles: getFilesReducer,
  getUserFolder : getUserFolderReducer
  // uploadProducts : uploadProductReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;

// userInfo: localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')): null
