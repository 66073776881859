import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Authentication.css";
import Navbar from "./Navbar/Navbar";
import instance from "./common/Baseurl";
import swal from "sweetalert";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import CountrySelect from "./CountrySelect";
import ReactTooltip from "react-tooltip";
// import "react-phone-number-input/style.css";
// import Select from "react-select";
// import countryList from "react-select-country-list";

function Register() {
  let history = useHistory();
  // const customStyles = {
  //   control: () => ({
  //     // none of react-select's styles are passed to <Control />
  //     width: "inherit",
  //     backgroundColor: "#DCE9FA",
  //     display: "flex",
  //     border: "none",
  //     borderRadius: "50rem",
  //     padding: "0.2rem 1rem",
  //   }),
  //   placeholder: (defaultStyles) => {
  //     return {
  //       ...defaultStyles,
  //       color: "#ffffff",
  //     };
  //   },
  // };
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  // const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [value, setValue] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);
  const [confirmPassword, setconfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [entity, setEntity]=useState("");
  const [property, setProperty] = useState("")
  const [turnover, setTurnover] = useState("")
  const [user_type, setUsertype] = useState("")
  // const options = useMemo(() => countryList().getData(), []);
  // console.log(value)
  const submitHandler = async (e) => {

    e.preventDefault();
    setloading(true);
    if (!(company && name && phone && country && email && password && user_type )) {
      setloading(false);
      return swal("All fields are mandatory.");
    }
    if (password.length < 8) {
      setloading(false);
      return swal("Password should be at least 8 characters");
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      setloading(false);
      return swal("Please enter a valid email address");
    }
    if (phone)
 {
      if (isValidPhoneNumber(phone)
 === false) {
        setloading(false);
        return swal(
          "Please enter a valid phone number as per chosen country norms"
        );
      } // Returns `true` or `false`
    }
    if (password !== confirmPassword) {
      setloading(false);
      return swal("Passwords do not match");
    }
    instance
      .post("/register", {
        company: company,
        name: name,
        address: country,
        email: email,
        phone: phone,
        password: password,
        entity_type : entity,
        turnover: turnover,
        property: property,
        user_type: user_type,
        created_by: ''
      })
      .then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          setloading(false);
          if(res.data.response=='error')
          {
            swal('Something went wrong', `${res.data.message}`)
          }
          else
          {
            await swal(
              "Successful! Login credentials are below",
              `User id = ${res.data.userid}, Password = ${res.data.password}`,
              "success",
              { button: true }
            );
            instance
              .post("/login", {
                email: res.data.userid,
                password: password,
              })
              .then(async (response) => {
                if (!response.data.auth) {
                  setloading(false);
                  await swal(response.data.message, "", "info");
                } else {
                  localStorage.setItem("token", response.data.token);
                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify(response.data.user)
                  );
                  instance.post("/getUserFolder", {
                    user_id: response.data.user.user_id,
                  })
                    .then((res) => {
                      console.log('Createuserfolder', res);
                      setloading(false);
                      history.push("/profile");
                    });
                }
              })
              .catch((err) => {
                if (err.response) {
                  // client received an error response (5xx, 4xx)
                  setloading(false);
                  if (err.response.status >= 400 && err.response.status <= 499)
                    return swal(
                      "Uh-oh.",
                      "something was not right on your end, please try again",
                      "info"
                    );
                  else if (
                    err.response.status >= 500 &&
                    err.response.status <= 599
                  )
                    return swal(
                      "Uh-oh..Error 500",
                      "Something went wrong at our end. Sorry about that",
                      "info"
                    );
                } else if (err.request) {
                  // client never received a response, or request never left
                  setloading(false);
                  return swal(
                    "Network error",
                    "The network connection is lost,please try after some time",
                    "info"
                  );
                } else {
                  // anything else
                  console.log(err);
                  setloading(false);
                  return swal("something went wrong", "", "info");
                }
              });
          }
        }
      })
      .catch((err) => {
        setloading(false);
        if (err.response) {
          // client received an error response (5xx, 4xx)
          if (err.response.status >= 400 && err.response.status <= 499) {
            if (err.response.status !== 406)
              return swal(
                "Uh-oh.",
                "something was not right on your end, please try again",
                "info"
              );
            return swal(
              "email id already in use",
              "Try logging in with this email or enter a new value here",
              "info"
            );
          } else if (err.response.status >= 500 && err.response.status <= 599)
            return swal(
              "Uh-oh..Error 500",
              "Something went wrong at our end. Sorry about that",
              "info"
            );
        } else if (err.request) {
          // client never received a response, or request never left
          return swal(
            "Network error",
            "The network connection is lost,please try after some time",
            "info"
          );
        } else {
          // anything else
          return swal(
            "something went wrong",
            "we are trying to solve the problem",
            "info"
          );
        }
      });
  };
  return (
    <>
      <Navbar />
      <div className="w-100 p-sm-4 p-5">
        <form
          style={{ backgroundColor: "white" }}
          className="w-100 d-flex flex-column justify-content-between align-items-center"
          onSubmit={submitHandler}
        >
          <h1 style={{ color: "#363636" }} className="mb-5 text-center">
            Let's Start, it's Easy
          </h1>
          <div className="container w-100 d-flex flex-column justify-content-between flex-wrap">
            <div className="div d-flex flex-column flex-sm-row justify-content-between">
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                  Company
                </label>
                <input
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  type="text"
                  id="company"
                  autoComplete="off"
                  value={company}
                  required
                  onChange={(e) => setCompany(e.target.value)}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="Company name"
                />
              </div>
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                  Contact person
                </label>
                <input
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  type="text"
                  id="name"
                  autoComplete="off"
                  value={name}
                  required
                  data-tip="Enter only letters"
                  onChange={(e) => {
                    let value = e.target.value;

                    value = value.replace(/[^A-Za-z]/gi, "");

                    setName(value);
                  }}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="Name"
                />
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  type="info"
                  event="focusin"
                  eventOff="focusout"
                />
              </div>
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                  Email
                </label>
                <input
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  type="email"
                  id="email"
                  autoComplete="off"
                  value={email}
                  required
                  data-tip="Enter a valid email address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="abc@xyz.com"
                />
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  type="info"
                  event="focusin"
                  eventOff="focusout"
                />
              </div>
            </div>
            <div className="div d-flex flex-column flex-sm-row justify-content-between">
              <div className="mb-3 width-custom-2">
                <div className="w-100 d-flex justify-content-between">
                  <label style={{ color: "#363636" }} className="form-label">
                    Password
                  </label>
                </div>
                <div className="position-relative">
                  <div
                    className={password.length===0?"d-none":"position-absolute"}
                    style={{
                      right: "7%",
                      top: "50%",
                      transform: "translate(0,-50%)",
                      cursor: "pointer"
                    }}
                  >
                    {showPassword ? (
                      <small
                        onClick={() => setshowPassword(!showPassword)}
                        className="text-primary"
                      >Hide</small>
                    ) : (
                      <small
                        onClick={() => setshowPassword(!showPassword)}
                        className="text-primary"
                      >Show</small>
                    )}
                  </div>
                  <input
                    style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="off"
                    value={password}
                    required
                    data-tip="Passwords must be atleast 8 characters long"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control border-0 rounded-pill py-2 px-4"
                    placeholder="Password"
                  />
                  <ReactTooltip
                    place="bottom"
                    effect="solid"
                    type="info"
                    event="focusin"
                    eventOff="focusout"
                  />
                </div>
              </div>
              <div className="mb-3 width-custom-2">
              <div className="w-100 d-flex justify-content-between">
                <label style={{ color: "#363636" }} className="form-label">
                  Confirm password
                </label>
              </div>
              <div className="position-relative">
              <div
                    className={confirmPassword.length===0?"d-none":"position-absolute"}
                    style={{
                      right: "7%",
                      top: "50%",
                      transform: "translate(0%,30%)",
                      cursor: "pointer" 
                    }}
                  >
                    {showconfirmPassword ? (
                      <small
                        onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                        className="text-primary"
                      >Hide</small>
                    ) : (
                      <small
                        onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                        className="text-primary"
                      >Show</small>
                    )}
                  </div>
              </div>
                <input
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  type={showconfirmPassword ? "text" : "password"}
                  id="confirmpassword"
                  autoComplete="off"
                  value={confirmPassword}
                  required
                  onChange={(e) => setconfirmPassword(e.target.value)}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="Confirm password"
                />
              </div>
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                  Country/region
                </label>
                <CountrySelect
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  labels={en}
                  value={country}
                  onChange={setCountry}
                  
                />
              </div>
            </div>
            <div className="div d-flex flex-column flex-sm-row justify-content-between">
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                  Phone number
                </label>
                <PhoneInput
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  country={country}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="Phone number"
                  withCountryCallingCode={true}
                  countryCallingCodeEditable={false}
                  international={true}
                  rules={{ required: true }}
                  data-tip="Phone number must be according to the country rules.
                   Enter only numeric values"
                  value={value}
                  onChange={setPhone}
                  required
                />
                
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  type="info"
                  event="focusin"
                  eventOff="focusout"
                  multiline={true}
                />
               
                
              </div>
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                      Entity Type
                </label>
                <select
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  id="entity-type"
                  value={entity}
                  required
                  onChange={(e) => {
                    setEntity(e.target.value)
                  }}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="Entity Type"
                >
                  <option>Choose Company</option>
                  <option value='Holding Company'>Holding Company</option>
                  <option value='Trading Company'>Trading Company</option>
                  <option value='Service Company'>Service Company</option>
                  <option value='Consulting Company'>Consulting Company</option>
                  <option value='Manufacturing Company'>Manufacturing Company</option>
                </select>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  type="info"
                  event="focusin"
                  eventOff="focusout"
                />
              </div>

              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                      Property
                </label>
                <select
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  id="property"
                  value={property}
                  required
                  onChange={(e) => {
                    setProperty(e.target.value)
                  }}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="Property"
                >
                  <option>Choose Property</option>
                  <option value='Movable'>Movable</option>
                  <option value='Financial Assets'>Financial Assets</option>
                  <option value='Intangible Assets'>Intangible Assets</option>
                </select>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  type="info"
                  event="focusin"
                  eventOff="focusout"
                />
              </div>
            </div>
            <div className="div d-flex flex-column flex-sm-row justify-content-between">
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                    Turnover
                </label>
                <select
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  id="Turnover"
                  value={turnover}
                  required
                  onChange={(e) => {
                    setTurnover(e.target.value)
                  }}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="Turnover"
                >
                  <option>Turnover Type</option>
                  <option value='Less than USD 3M'>Less than USD 3M</option>
                  <option value='More than USD 3M'>More than USD 3M</option>
                </select>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  type="info"
                  event="focusin"
                  eventOff="focusout"
                />
              </div>
              <div className="mb-3 width-custom-2">
                <label style={{ color: "#363636" }} className="form-label">
                  User type
                </label>
                <select
                  style={{ backgroundColor: "#DCE9FA", outline: "none" }}
                  id="Usertype"
                  value={user_type}
                  required
                  onChange={(e) => {
                    setUsertype(e.target.value)
                  }}
                  className="form-control border-0 rounded-pill py-2 px-4"
                  placeholder="User type"
                >
                  <option>User type</option>
                  <option value='2'>Professional Intermediary</option>
                  <option value='3'>Direct Client</option>
                </select>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  type="info"
                  event="focusin"
                  eventOff="focusout"
                />
              </div>
            </div>
          </div>
          <button
            className="m-3 btn btn-primary rounded-pill px-4 py-2"
            type="submit"
          >
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Create Account"
            )}
          </button>
          <div className="signin-link">
            Already have an account? {""}{" "}
            <Link
              style={{ textDecoration: "none", color: "#0564DC" }}
              to="/signin"
            >
              Log in!
            </Link>
          </div>
        </form>
      </div>
    </>
  );
} 


export default Register;
