import React, { Fragment, useState, useRef, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import instance from "../../common/Baseurl";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../Datepicker.css'
const FileUpload = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  let { id, name, user_id } = useParams();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const ref = useRef();
  const [file, setFile] = useState([]);
  const [fileCategory, setFileCategory] = useState('')
  const [fileSubCategory, setFileSubCategory] = useState('')
  const [loading, setloading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [notloaded, setnotloaded] = useState(true);
  let history = useHistory();
  const [fileArr, setFileArr] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [ folder_name, setFolderName] = useState({})
  // useEffect(() => {
  //   if(props.folders && props.folders[0].length>0){
  //     let obj = props.folders[0].find(f => f.id===id)
  //     setFolderName(obj)
  //   }
    
  // }, [])
  useEffect(() => {
    return () => {
      setUploadedFile([]);
    };
  }, []);

  useEffect(() => {
    instance.get("/getFileFolder/" + id, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        setFileArr(res.data.files)
      })
  }, [id])

  useEffect(() => {
    instance.get('/category/' + userInfo.user_id)
      .then(res => {
        //console.log(res.data)
        setCategory(res.data)
      })
      .catch(err => console.log(err))
  }, [])
  // useEffect(() => {
  //   instance.get('/sub_category/' + userInfo.id + '/' + fileCategory)
  //     .then(res => {
  //       console.log(res.data)
  //       setSubCategory(res.data)
  //     })
  //     .catch(err => console.log(err))
  // }, [])

  const onChange = (e) => {
    setFile(e.target.files);
    setnotloaded(false);
  };

  const onCategoryChange = (e) => {
    //console.log(e.target.value)
    setFileCategory(e.target.value)
    instance.get('/sub_category/' + userInfo.user_id + '/' + e.target.value)
      .then(res => {
        // console.log(res.data)
        setSubCategory(res.data)
      })
      .catch(err => console.log(err))
  }

  const onSubCategoryChange = (e) => {
    //console.log(e.target.value)
    setFileSubCategory(e.target.value)
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    if (fileCategory === '' || fileSubCategory === '') {
      swal(
        "Invalid Details.",
        "Please Enter Valid Categorty or SubCategory.",
        "warning",
      );
      setnotloaded(true);
      ref.current.value = "";
      setFile([]);
      return;
    }
    if (ref.current.value === "") return swal("Please select a file first!");
    if (
      ref.current.value.split(".").pop().toLowerCase() !== "docx" &&
      ref.current.value.split(".").pop().toLowerCase() !== "xlsx" &&
      ref.current.value.split(".").pop().toLowerCase() !== "xls" &&
      ref.current.value.split(".").pop().toLowerCase() !== "csv" &&
      ref.current.value.split(".").pop().toLowerCase() !== "pdf" &&
      ref.current.value.split(".").pop().toLowerCase() !== "png" &&
      ref.current.value.split(".").pop().toLowerCase() !== "jpeg" &&
      ref.current.value.split(".").pop().toLowerCase() !== "jpg" &&
      ref.current.value.split(".").pop().toLowerCase() !== "zip"
    ) {
      swal(
        "Invalid file formats.",
        "Only .docx, .xlsx, .xls, .csv, .pdf, .jpg , png and .zip are valid"
      );
      setnotloaded(true);
      ref.current.value = "";
      setFile([]);
      return;
    }
    const fN = ref.current.value.split("\\").pop()

    for (var i = 0; i < file.length; i++) {
      let result = fileArr.find(e => e.name.trim() === file[i].name.trim())
      if (fileArr.length > 0 && result) {
        if (fN.trim() === result.name.trim()) {
          swal(
            "File already exists",
            `${fN} already exists.`,
            "warning",
          );
          setnotloaded(true);
          ref.current.value = "";
          setFile([]);
          return;
        }
      }
    }
    setloading(true);
    const formData = new FormData();
    for (const [key, value] of Object.entries(file)) {
      formData.append("content", value);
    }
    formData.append("id", id);
    formData.append('user_id', user_id?user_id:userInfo.user_id)
    formData.append('category', fileCategory)
    formData.append('sub_category', fileSubCategory)
    formData.append('file_date', startDate)
    formData.append('folder_name',name)
    // console.log(formData);
    const folderId = id;
    const foldername = name;
    instance
      .post("/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "x-access-token": localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        console.log(res)
        // if (res.data.auth === false) {
        //   setloading(false);
        //   await swal("Please login first", "", "error");
        //   props.history.push("/signin");
        //   return;
        // }
        if(res.data.error)
        {
          setloading(false);
          await swal(res.data.error, "", "warning");
        }
        else
        {
          const { id, name } =
            res.data;
          // const type = file.type;

          setUploadedFile({ id, name });
          setloading(false);
          await swal("File uploaded successfully!", "", "success");
          setnotloaded(true);
          ref.current.value = "";
          setFile([]);
          setTimeout(() => {
            props.history.push(`/profile/folders/files/${folderId}/${foldername}/${user_id?user_id:userInfo.user_id}`); //baseurl integration
          }, 3000);
        //props.history.push(`/profile/folders/${folderId}`); //baseurl integration
        }
        
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setloading(false);
          swal(
            "There was a problem with the server",
            "Please try again after sometime",
            "info"
          );
        } else if (err.response.status === 406) {
          setloading(false);
          swal("File already exists");
          ref.current.value = "";
          setFile([]);
        } else {
          setloading(false);
          swal(
            "Uh-oh, something was not right on your end",
            "please try again",
            "info"
          );
          ref.current.value = "";
          setFile([]);
        }
      });
  };

  return (
    <Fragment>

      <div className="w-100 h-100 container d-flex flex-column justify-content-between align-items-center text-center"
        style={{ marginTop: '8rem' }}
      >
        <form
          style={{ border: "1px solid #363636", backgroundColor: "#c1dcff" }}
          className="dimensions-upload rounded-3 overflow-auto p-5"
          onSubmit={onSubmit}
        >
          <div className="custom-file mt-4 text-center" style={{ width: "100%", margin: "auto" }}>
          <div >
            <h2>
              <i className="ri-arrow-left-circle-line"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
                style={{ color: '#084caa', float: 'left'}}>
              </i>
            </h2>
          </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 text-center " >
                <h4>Upload Files</h4>
              </div>
            </div>

            <input
              type="file"
              className="form-control bs-custom-file-input"
              
              id="customFile"
              onChange={onChange}
              ref={ref}
              multiple
            />
            <h6 className="text-left" style={{ fontSize: '10px' }}>
              Supported file extensions: .jpg,.jpeg,.pdf,.png,.docx,.xlsx,.csv,.zip
            </h6>

            <div className="row">
              <div className="col-md-4">
                <select
                  value={fileCategory}
                  onChange={onCategoryChange}
                  style={{ border: '1px solid #084caa', color: '#084caa', borderRadius: '16px', padding: '0.5rem', width: '100%' }} >
                  <option>---Category---</option>
                  {category && category.map((data) => {
                    return (
                      <option value={data.category} key={data.id}>{data.category}</option>
                    )
                  })}
                </select>

              </div>
              <div className="col-md-4">
                <select
                  value={fileSubCategory}
                  onChange={onSubCategoryChange}
                  style={{ border: '1px solid #084caa', color: '#084caa', borderRadius: '16px', padding: '0.5rem', width:'100%'}}>
                  <option>---SubCategory---</option>
                  {subCategory && subCategory.map((data) => {
                    return (
                      <option value={data.sub_category} key={data.id}>{data.sub_category}</option>
                    )
                  })}
                </select>

              </div>
              <div className="col-md-4">
                {/* <input type="date" defaultValue={startDate} onChange={date => setStartDate(date)} /> */}
                <DatePicker selected={startDate} dateFormat='yyyy-MM-dd' onChange={date => setStartDate(date)} showMonthDropdown showYearDropdown 
                  />
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              style={{ display: notloaded ? "none" : "inline-block", border: '1px solid #084caa', backgroundColor: '#084caa', color: 'white' }}
              className="btn btn-primary rounded-pill px-3 justify-content-center"
              type="submit"
            >
              {loading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Upload file/image"
              )}
            </button>
          </div>
          

        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    folders: state.getFolders.folders,
  };
};
export default connect(mapStateToProps)(withRouter(FileUpload));
