import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Switch } from "react-router-dom";
import "./App.css";
import React from "react";
import Signin from "./components/Signin";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard/Dashboard";
import Pagenotfound from './components/Pagenotfound'
import PublicRoute from './routes/PublicRoute'
import PrivateRoute from './routes/PrivateRoute'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute restricted={true} path="/signin" component={Signin}></PublicRoute>
        <PrivateRoute path="/profile" component={Dashboard}></PrivateRoute>
        <PublicRoute restricted={true} exact path="/" component={Register}></PublicRoute>
        <PublicRoute restricted={false} component={Pagenotfound}/>
      </Switch>
    </BrowserRouter>
    
  );
}

export default App;