import { READ_FOLDERS,REMOVE_FOLDERS,READ_FILES,REMOVE_FILES,READ_USER_FOLDER,REMOVE_USER_FOLDER } from "../constants/productConstants";

const initialState = {
  folders: [],
};
const initialState2 = {
  files: [],
}
const initialState3 = {
  userfolder: {}
}

export const getFoldersReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_FOLDERS:
      const newData = [];
      if(action.payload.length > 0) action.payload.map(file =>newData.push(file))
      // console.log('reducer',newData);
      return {
        ...state,
        folders: newData,
      };
    case REMOVE_FOLDERS:
      return{ 
        ...state,
        folders:[]
      }
    default:
      return state;
  }
};

export const getUserFolderReducer = (state = initialState3, action) => {
  switch (action.type) {
    case READ_USER_FOLDER:
      return {
        ...state,
        userfolder: action.payload,
      };
    case REMOVE_USER_FOLDER:
      return{ 
        ...state,
        userfolder:{}
      }
    default:
      return state;
  }
};

export const getFilesReducer = (state = initialState2, action) =>{
switch (action.type) {
  case READ_FILES:
      const newDatafile = [];
      if(action.payload.length > 0) action.payload.map(file =>newDatafile.push(file))
      return {
        ...state,
        files: newDatafile,
      };
      case REMOVE_FILES:
      return {
        ...state,
        files:[]
      };
      default:
      return state;
}
}
