import React from 'react'

function Pagenotfound() {
    return (
        <div className="text-center mt-5 container alert alert-danger p-4">
            The page you requested was not found!
        </div>
    )
}

export default Pagenotfound
