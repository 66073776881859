import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { NavLink, withRouter, useHistory } from "react-router-dom";
import instance from "../../common/Baseurl";

function Trade(props) {
    const history = useHistory()
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const [category, setcategory] = useState([])
    useEffect(() => {
        instance.get('/category/' + userInfo.user_id )
            .then(res => {
                setcategory(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <>
            {category.length > 0 ? <>
                <ul className="w-100 list-group"
                style={{display: 'block'}}>
                {category && category.map(data => {
          return (
          <li key={data.id}
              
              onClick={() => {
                history.push(`/profile/folders/${userInfo.user_id}/${data.category}`);
              }}
              className="mx-5 py-1 border-0 text-dark list-group-item" style={{background: 'white', listStyle:'none', cursor:'pointer'}} 
            >
              <span>{data.category}</span>
           </li> 
          )
        })}
                </ul>
            </> :
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}
        </>
    )
}

export default Trade
