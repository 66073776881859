import React, { Fragment, useState, useRef, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import instance from "../common/Baseurl";
const Support = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const [loading, setloading] = useState(false);
  const [name, setName] = useState(userInfo.name)
  const [email, setEmail] = useState(userInfo.email)
  const [user_id, setUserID] = useState(userInfo.user_id)
  const [description,setDesc] = useState('')
  const onSubmit = (e) =>{
    e.preventDefault()
    setloading(true)
    // console.log()
    instance.post('/support', { name: name, email: email, description: description, user_id:user_id }).then(res=>{
      // console.log(res)
      if(res.data.response=='success')
      {
        swal('Submitted','Your query is submitted',{button:true}).then(()=>{
          window.location.reload()
        })
        
      }
      setloading(false)
    }).catch(err=>console.log(err))
  }
  return (
    <Fragment>

      <div className="w-75 h-100 container d-flex flex-column justify-content-between align-items-center"
        style={{ marginTop: '8rem' }}
      >
        
        <form
          style={{ border: "1px solid #363636", backgroundColor: "#c1dcff" }}
          className="dimensions-upload d-flex flex-column justify-content-evenly align-items-center rounded-3 overflow-auto p-4"
          onSubmit={onSubmit}
        >
          <h5>Submit a query</h5>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Name</label>
                <input type="text" name="name" className="form-control" value={userInfo.name} onChange={(e)=>{setName(e.target.value)}} required />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Email</label>
                <input type="email" name="email" className="form-control" value={userInfo.email} onChange={(e) => { setEmail(e.target.value) }} required />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label>Description</label>
                <textarea className="form-control" name='description' onChange={(e) => { setDesc(e.target.value) }} required></textarea>
              </div>
            </div>
          </div>
          <button
            style={{ border: '1px solid #084caa', backgroundColor: '#084caa', color: 'white' }}
            className="btn btn-primary rounded-pill px-3"
            type="submit"
          >
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>

        </form>
      </div>
    </Fragment>
  );
};

export default Support;
