import React, { useState, useEffect } from "react";
import instance from "../../common/Baseurl";
import swal from "sweetalert";
import { useHistory } from "react-router";
// import CountrySelect from "../../CountrySelect";
// import en from "react-phone-number-input/locale/en.json";
// import PhoneInput from "react-phone-number-input/input";
import {
  removeuserFolders,
  removeuserFiles,
} from "../../../actions/productActions";
import { connect } from "react-redux";
// import { parsePhoneNumber } from 'react-phone-number-input';
// import { isValidPhoneNumber } from "react-phone-number-input";

function Userprofile(props) {
  const [loading, setloading] = useState(false);
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [company, setcompany] = useState("");
  const [id, setid] = useState("");
  // const [inputphonevalue, setinputphoneValue] = useState();
  const [showForm, setshowForm] = useState(false);
  const [showSecondForm, setshowSecondForm] = useState(false);
  const [password, setPassword] = useState("");
  const [newpassword, setnewPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false); //to display the password
  const [shownewPassword, setshownewPassword] = useState(false); //to display the password
  const [confirmPassword, setconfirmPassword] = useState("");
  const [showconfirmPassword,setshowconfirmPassword] = useState(false)
  // const phoneNumber = parsePhoneNumber(String(value))
  // if (phoneNumber) {
  //   if(phoneNumber.country !== country) setValue(undefined)
  // }
  
  let history = useHistory();
  useEffect(() => {
    const { email, name, company, id } = JSON.parse(
      localStorage.getItem("userInfo")
    );
    setemail(email);
    setname(name);
    setcompany(company);
    setid(id);
    return () => {
      // setinputphoneValue("")
      setemail("");
      setname("");
      setcompany("");
      setid("");
    };
  }, []);
  
  const update = (e) => {
    e.preventDefault();
    setloading(true);
    if (name.trim() === "") {
      setloading(false);
      return swal("Name Field can not be blank");
    }
    // if(inputphonevalue === undefined){
    //   setloading(false);
    //   return swal("Please enter phone number again before continuing");
    // }
    // if (inputphonevalue) {
    //   if (isValidPhoneNumber(inputphonevalue) === false) {
    //     setloading(false);
    //     return swal(
    //       "Please enter a valid phone number as per chosen country norms"
    //     );
    //   } // Returns `true` or `false`
    // }
    instance
      .post(`/updateinfo/${id}`, {
        company: company,
        name: name,
        email: email,
        // inputphonevalue : inputphonevalue,
        // country: country,
      })
      .then(async (res) => {
        if (res.status === 200) {
          setloading(false);
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              company: company,
              email: email,
              id: id,
              name: name
            })
          );
          await swal("Profile updated", "", "success");
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          setloading(false);
          if (err.response.status >= 400 && err.response.status <= 499)
            return swal(
              "Uh-oh.",
              "something was not right on your end, please try again",
              "info"
            );
          else if (err.response.status >= 500 && err.response.status <= 599)
            return swal(
              "Uh-oh..Error 500",
              "Something went wrong at our end. Sorry about that",
              "info"
            );
        } else if (err.request) {
          // client never received a response, or request never left
          setloading(false);
          return swal(
            "Network error",
            "The network connection is lost,please try after some time",
            "info"
          );
        } else {
          // anything else
          console.log(err);
          setloading(false);
          return swal("something went wrong", "", "info");
        }
      });
  };
  const updatePassword = (e) => {
    e.preventDefault();
    setloading(true);
    if (newpassword !== confirmPassword) {
      setloading(false);
      return swal("Your passwords do not match");
    }
    instance
      .post(`/resetpassword`, {
        email: email,
        password: password,
        newpassword: newpassword,
      })
      .then(async (res) => {
        if (res.data.auth === false) {
          setloading(false);
          return swal("Uh-oh.", res.data.message, "info");
        }
        setloading(false);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            company: company,
            email: email,
            id: id,
            name: name
          })
        );
        await swal(
          "Password changed successfully",
          "Please login again",
          "success"
        );
        props.removeuserFolders();
        props.removeuserFiles();
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        return props.history.push("/signin");
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          setloading(false);
          if (err.response.status >= 400 && err.response.status <= 499)
            return swal(
              "Uh-oh.",
              "something was not right on your end, please try again",
              "info"
            );
          else if (err.response.status >= 500 && err.response.status <= 599)
            return swal(
              "Uh-oh..Error 500",
              "Something went wrong at our end. Sorry about that",
              "info"
            );
        } else if (err.request) {
          // client never received a response, or request never left
          setloading(false);
          return swal(
            "Network error",
            "The network connection is lost,please try after some time",
            "info"
          );
        } else {
          // anything else
          console.log(err);
          setloading(false);
          return swal("something went wrong", "", "info");
        }
      });
  };
  return (
    <div className="position-relative w-100 h-100 p-5 overflow-auto">
      <button
        style={{ display: showForm || showSecondForm ? "none" : "" }}
        className="mb-sm-3 mb-5 border-0 border-bottom text-primary border-primary bg-transparent"
        onClick={(e) => {
          e.preventDefault();
          setloading(false);
          history.push(`/profile/folders`);
        }}
      >
        Go to home
      </button>
      <div
        style={{ display: showForm || showSecondForm ? "none" : "" }}
        className="w-100"
      >
        <div className="w-100 d-flex flex-sm-row flex-column justify-content-between align-items-center">
          <p>Email:</p>
          <p>{email}</p>
        </div>
        <hr />
        <div className="w-100 d-flex flex-sm-row flex-column justify-content-between align-items-center">
          <p>Name:</p>
          <p>{name}</p>
        </div>
        <hr />
        <div className="w-100 d-flex flex-sm-row flex-column justify-content-between align-items-center">
          <p>Company:</p>
          <p>{company}</p>
        </div>
        <hr />
        <div className="d-flex flex-sm-row flex-column justify-content-center align-items-center">
          <button
            className="my-sm-2 my-3 mx-3 btn btn-primary rounded-pill px-4 py-1"
            onClick={() => setshowForm(true)}
          >
            Edit profile
          </button>
          <button
            className="my-sm-2 my-3 btn border-primary text-primary rounded-pill px-2 py-1"
            onClick={() => setshowSecondForm(true)}
          >
            Change Password
          </button>
        </div>
      </div>
      <form
        style={{ display: showForm ? "flex" : "none" }}
        onSubmit={update}
        className="container w-100 h-100 flex-column align-items-center"
      >
        <h2>My Profile</h2>
        <div className="mt-sm-2 mt-3 mb-sm-2 mb-3 width-custom">
          <label style={{ color: "#363636" }} className="form-label">
            Email
          </label>
          <input
            className="form-control border-0 rounded-pill py-2 px-4 text-muted"
            type="email"
            id="email"
            name="email"
            autoComplete="off"
            placeholder="Username or email"
            value={email}
            disabled
            onChange={(e) => setemail(e.target.value)}
          ></input>
        </div>
        <div className="mb-sm-2 mb-3 width-custom">
          <label style={{ color: "#363636" }} className="form-label">
            Name
          </label>
          <input
            style={{ backgroundColor: "#DCE9FA", outline: "none" }}
            className="form-control border-0 rounded-pill py-2 px-4"
            type="name"
            name="name"
            id="name"
            autoComplete="off"
            placeholder="Name"
            value={name}
            required
            onChange={(e) => setname(e.target.value)}
          ></input>
        </div>
        <div className="mb-sm-2 mb-3 width-custom">
          <label style={{ color: "#363636" }} className="form-label">
            Company name
          </label>
          <input
            className="form-control border-0 rounded-pill py-2 px-4"
            type="company"
            name="company"
            id="company"
            autoComplete="off"
            placeholder="company"
            value={company}
            // disabled
            onChange={(e) => setcompany(e.target.value)}
          ></input>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="my-sm-2 my-3 mx-sm-3 btn btn-primary rounded-pill px-4 py-1"
            type="submit"
          >
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Update"
            )}
          </button>
          <button
            className="my-sm-2 my-3 btn btn-secondary rounded-pill px-4 py-1"
            onClick={(e) => {
              e.preventDefault();
              setloading(false);
              setshowForm(false);
            }}
          >
            Go back
          </button>
        </div>
      </form>
      <form
        style={{ display: showSecondForm ? "flex" : "none" }}
        onSubmit={updatePassword}
        className="container w-100 flex-column align-items-center"
      >
        <h2>Change Password</h2>
        <div className="mb-3 width-custom-2">
          <div className="w-100 d-flex justify-content-between">
            <label style={{ color: "#363636" }} className="form-label">
              Old Password
            </label>
          </div>
          <div className="position-relative">
            <div
              className={password.length===0?"d-none":"position-absolute"}
              style={{
                right: "7%",
                top: "50%",
                transform: "translate(0,-50%)",
              }}
            >
              {showPassword ? (
                      <small
                        onClick={() => setshowPassword(!showPassword)}
                        className="text-primary"
                      >Hide</small>
                    ) : (
                      <small
                        onClick={() => setshowPassword(!showPassword)}
                        className="text-primary"
                      >Show</small>
                    )}
            </div>
            <input
              style={{ backgroundColor: "#DCE9FA", outline: "none" }}
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="off"
              value={password}
              required
              data-tip="Passwords must be atleast 8 characters long"
              onChange={(e) => setPassword(e.target.value)}
              className="form-control border-0 rounded-pill py-2 px-4"
              placeholder="Password"
            />
          </div>
        </div>
        <div className="mb-3 width-custom-2">
          <div className="w-100 d-flex justify-content-between">
            <label style={{ color: "#363636" }} className="form-label">
              New Password
            </label>
          </div>
          <div className="position-relative">
            <div
              className={newpassword.length===0?"d-none":"position-absolute"}
              style={{
                right: "7%",
                top: "50%",
                transform: "translate(0,-50%)",
              }}
            >
              {shownewPassword ? (
                      <small
                        onClick={() => setshownewPassword(!shownewPassword)}
                        className="text-primary"
                      >Hide</small>
                    ) : (
                      <small
                        onClick={() => setshownewPassword(!shownewPassword)}
                        className="text-primary"
                      >Show</small>
                    )}
            </div>
            <input
              style={{ backgroundColor: "#DCE9FA", outline: "none" }}
              type={shownewPassword ? "text" : "password"}
              id="newpassword"
              autoComplete="off"
              value={newpassword}
              required
              data-tip="Passwords must be atleast 8 characters long"
              onChange={(e) => setnewPassword(e.target.value)}
              className="form-control border-0 rounded-pill py-2 px-4"
              placeholder="New Password"
            />
          </div>
        </div>
        <div className="mb-3 width-custom-2">
        <div className="w-100 d-flex justify-content-between">
            <label style={{ color: "#363636" }} className="form-label">
              New Password
            </label>
          </div>
          <div className="position-relative">
              <div
                    className={confirmPassword.length===0?"d-none":"position-absolute"}
                    style={{
                      right: "7%",
                      top: "50%",
                      transform: "translate(0%,30%)",
                      cursor: "pointer" 
                    }}
                  >
                    {showconfirmPassword ? (
                      <small
                        onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                        className="text-primary"
                      >Hide</small>
                    ) : (
                      <small
                        onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                        className="text-primary"
                      >Show</small>
                    )}
                  </div>
            </div>
          <input
            style={{ backgroundColor: "#DCE9FA", outline: "none" }}
            type="password"
            id="confirmpassword"
            autoComplete="off"
            value={confirmPassword}
            required
            onChange={(e) => setconfirmPassword(e.target.value)}
            className="form-control border-0 rounded-pill py-2 px-4"
            placeholder="Confirm password"
          />
        </div>
        <div className="d-flex align-items-center">
          <button
            className="my-sm-2 my-3 mx-sm-3 btn btn-primary rounded-pill px-4 py-1"
            type="submit"
          >
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Update"
            )}
          </button>
          <button
            className="my-sm-2 my-3 btn btn-secondary rounded-pill px-4 py-1"
            onClick={(e) => {
              e.preventDefault();
              setloading(false);
              setshowSecondForm(false);
            }}
          >
            Go back
          </button>
        </div>
      </form>
    </div>
  );
}

const mapDispatchToProps = {
  removeuserFolders,
  removeuserFiles, 
};

export default connect(null, mapDispatchToProps)(Userprofile);
